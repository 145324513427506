import React, { useRef } from "react";
import { useGLTF } from "@react-three/drei";
import {Canvas, useFrame, useThree, useLoader} from "@react-three/fiber";
import * as THREE from "three";
import metal from "./metaltxt.jpg";
import {WebGLCubeRenderTarget} from "three";
import {RGBFormat} from "three";
import {LinearMipmapLinearFilter} from "three";
import {CubeCamera, TextureLoader} from "three";

const scale = 0.04

export function Model(props) {
    const { scene, gl } = useThree();
    const [colorMap, normalMap, roughnessMap, metalnessMap] = useLoader(TextureLoader, [
        './coast_sand_rocks_02_diff_4k.jpg',
        './coast_sand_rocks_02_nor_gl_4k.jpg',
        './coast_sand_rocks_02_rough_4k.jpg',
    ])
    // The cubeRenderTarget is used to generate a texture for the reflective sphere.
    // It must be updated on each frame in order to track camera movement and other changes.
    const cubeRenderTarget = new WebGLCubeRenderTarget(2048, {
        format: RGBFormat,
        generateMipmaps: true,
        minFilter: LinearMipmapLinearFilter
    });
    const cubeCamera = new CubeCamera(1, 1000, cubeRenderTarget);
    cubeCamera.position.set(0, 0, 0);

    const { nodes, materials } = useGLTF("/smklogo.glb");
    console.log(nodes)
    const mesh = useRef();
    useFrame(() => {
        cubeCamera.update(gl, scene);
        scene.add(cubeCamera);
        mesh.current.rotation.y = mesh.current.rotation.y += 0
    });

    scene.add(cubeCamera);

    // Update the cubeCamera with current renderer and scene.
    useFrame(() => cubeCamera.update(gl, scene));

    //const base = new THREE.TextureLoader().load(metal);
    const base = new THREE.TextureLoader().load(metal);
    return (
        <group {...props} dispose={null} ref={mesh}>
            <ambientLight />
            <mesh
                wireframe={false}
                castShadow
                receiveShadow
                geometry={nodes.Mesh_0.geometry}
                //material={materials.Material_0}
                position={[0, 0, 0]}
                rotation={[0, 0, 0]}
                scale={[scale, scale, scale]}
            >
                <meshBasicMaterial
                    attach="material"
                    envMap={cubeCamera.renderTarget.texture}
                    //map={colorMap}
                    //normalMap={normalMap}
                    //roughnessMap={roughnessMap}
                    color="lightgrey"
                    roughness={0.1}
                    metalness={1}
                />
            </mesh>
        </group>
    );
}

useGLTF.preload("/smklogo.glb");
