import React, {useRef, useState} from "react";
import {useFrame, useThree} from "@react-three/fiber";
import {CubeCamera, LinearMipmapLinearFilter, RGBFormat, WebGLCubeRenderTarget} from "three";

export const OrbitingSpheres = ({count, radius, speed}) => {

    const [selectedSphere, setSelectedSphere] = useState(null);
    const groupRef = useRef();
    const {camera} = useThree();
    const {scene, gl} = useThree();
    // The cubeRenderTarget is used to generate a texture for the reflective sphere.
    // It must be updated on each frame in order to track camera movement and other changes.
    const cubeRenderTarget = new WebGLCubeRenderTarget(50, {
        format: RGBFormat,
        generateMipmaps: true,
        minFilter: LinearMipmapLinearFilter
    });

    const cubeCamera = new CubeCamera(10, 50, cubeRenderTarget);

    useFrame(() => {

        cubeCamera.update(gl, scene)
        cubeCamera.position.set(0, 0, 0);
        scene.add(cubeCamera);
    });

    useFrame(({clock}) => {
        if (selectedSphere) {
            const selectedSphereObj = groupRef.current.children[selectedSphere];
            camera.position.copy(selectedSphereObj.position);
            //camera.position.z += 5;
            return
        }

        // console.log(clock)
        const t = clock.getElapsedTime() * speed;

        groupRef.current.rotation.y = t;

        const angleIncrement = (2 * Math.PI) / count;

        for (let i = 0; i < count; i++) {
            const sphere = groupRef.current.children[i];
            const angle = angleIncrement * i;

            const x = radius * Math.cos(angle);
            const z = radius * Math.sin(angle);

            sphere.position.set(x, 0, z);
        }
    });

    return (
        <group ref={groupRef}>
            {Array.from({length: count}).map((_, i) => ((() => {

                    // The cubeRenderTarget is used to generate a texture for the reflective sphere.
                    // It must be updated on each frame in order to track camera movement and other changes.


                    // Update the cubeCamera with current renderer and scene.
                    // useFrame(() => {
                    //     cubeCamera.update(gl, scene)
                    // });

                    return (
                        <mesh visible castShadow onClick={(e) => {
                            console.log('STOP'); window.location.href = 'https://shop.solutionmakers.io/'
                            // window.history.pushState({id: "100"},
                            //     "Consulting - SMK", "/consulting");
                            setSelectedSphere(i)
                        }}>

                            <sphereGeometry attach="geometry" args={[0.6, 32, 32]}/>
                            <meshBasicMaterial
                                attach="material"
                                envMap={cubeCamera.renderTarget.texture}
                                color="lightgrey"
                                roughness={0.1}
                                metalness={1}
                            />
                        </mesh>
                    );
                })()
            ))}
        </group>
    );
}
