import React from "react";
import {Canvas, extend, useFrame, useThree} from "@react-three/fiber";
import {CubeCamera, LinearMipmapLinearFilter, RGBFormat, WebGLCubeRenderTarget} from "three";
import {OrbitControls} from "three/examples/jsm/controls/OrbitControls";
import "./styles.css";
import {Model} from "./Model";
import {SkyBox} from "./SkyBox";
import {OrbitingSpheres} from "./OrbitingSpheres";
import {CameraControls} from "./CameraControls";

extend({ OrbitControls });

// Geometry
function Sphere1(onClickCallback) {
    const { scene, gl } = useThree();
    // The cubeRenderTarget is used to generate a texture for the reflective sphere.
    // It must be updated on each frame in order to track camera movement and other changes.
    const cubeRenderTarget = new WebGLCubeRenderTarget(256, {
        format: RGBFormat,
        generateMipmaps: true,
        minFilter: LinearMipmapLinearFilter
    });
    const cubeCamera = new CubeCamera(1, 1000, cubeRenderTarget);
    cubeCamera.position.set(0, 0, 0);
    scene.add(cubeCamera);

    // Update the cubeCamera with current renderer and scene.
    useFrame(() => {
        cubeCamera.update(gl, scene)
    });

    return (
        <mesh visible  castShadow onClick={onClickCallback}  >

            <sphereGeometry attach="geometry" args={[0.1, 32, 32]} />
            <meshBasicMaterial
                attach="material"
                envMap={cubeCamera.renderTarget.texture}
                color="lightgrey"
                roughness={0.1}
                metalness={1}
            />
        </mesh>
    );
}

// Lights
function App() {
    return (
        <Canvas className="canvas" camera={{ fov: 60, position: [0, 2, 20]}}>
          <CameraControls />
            <OrbitingSpheres count={7} radius={7} speed={0.2} />
            <SkyBox />
            <Model />
        </Canvas>
    );
}

export default App;

